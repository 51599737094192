// extracted by mini-css-extract-plugin
export var mainRaised = "contact-module--mainRaised--2f24n";
export var main = "contact-module--main--3SZOr";
export var container = "contact-module--container--3AFrV";
export var statement = "contact-module--statement--35wQa";
export var header = "contact-module--header--JI62w";
export var space100 = "contact-module--space100--3Ct-a";
export var space50 = "contact-module--space50--2gQLY";
export var mobileFlex = "contact-module--mobileFlex--2R6MA";
export var genQuest = "contact-module--genQuest--2f2Rr";
export var contactInfo = "contact-module--contactInfo--1qsd3";
export var inputFields = "contact-module--inputFields--2CyzJ";
export var contactHeader = "contact-module--contactHeader--3HJ60";
export var numberFields = "contact-module--numberFields--1oHt8";
export var subscribe = "contact-module--subscribe--2EtSW";
export var blueBanner = "contact-module--blueBanner--zAEFS";