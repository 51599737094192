import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Subscribe from '../components/subscribe'
import ContactUs from '../components/contactUs'

const styles = require('../styles/contact.module.css')
const classNames = require('classname')

const ContactPage: React.FC = () => (
    <Layout>
        <SEO title="Contact" />
        <div className={styles.container}>
            <div className={classNames({ [styles.main]: true, [styles.mainRaised]: true })}>
                <div className={styles.blueBanner}>
                <div className={styles.space50}></div>
                    <div className={styles.space100}></div>
                <div className={styles.header}>
                        <h1 className={styles.statement}>Let's Connect</h1>
                    </div>
                    <div className={styles.space50}></div>
                    <div className={styles.space100}></div>
                </div>
                <div className={styles.mobileFlex}>
                    
                    <div className={styles.genQuest}>
                        <div>
                            <h1>Interested in partnering with Park to Give?</h1>
                            <p>We’d love the opportunity to help more people Park to Give!
                               If you have parking spaces you think would be great for Park to Give or have any other inquiries,
                               please reach out to us using the form below.</p>
                        </div>
                        <div className={styles.space50}></div>
                        <ContactUs/>
                    </div>
                    <div className={styles.contactInfo}>
                        <div>
                            <p className={styles.contactHeader}><b>➤ location</b></p>
                            <h2>1200 Park Street<br/> Columbia, SC 29201</h2>
                        </div>
                        <div>
                            <p className={styles.contactHeader}><b>☎ contact</b></p>
                            <h2>info@parktogive.com<br/> (803) 939-6831<br/> (M-F 9-5pm)</h2>
                            <a href="https://www.parktogive.com"><p>www.parktogive.com</p></a>
                            <a href="https://www.park.gives"><p>www.park.gives</p></a>
                        </div>
                    </div>
                    <div className={styles.space100}></div>
                    <div className={styles.subscribe}>
                    <Subscribe />
                    </div>
                </div>
            </div>
        </div>

    </Layout>

)

export default ContactPage